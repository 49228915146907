unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.WebCtrls;

type
  TForm1 = class(TForm)
    divHeader: THTMLDiv;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divHeader := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);

  divHeader.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 640;
    Height := 672;
    CSSLibrary := cssBootstrap;
    ElementClassName := 'd-flex flex-column h-100 w-100 bg-transparent justify-content-center align-items-center';
    ElementFont := efCSS;
    divHeader.SetParentComponent(Self);
    divHeader.Name := 'divHeader';
    divHeader.Left := 88;
    divHeader.Top := 91;
    divHeader.Width := 400;
    divHeader.Height := 100;
    divHeader.ElementClassName := 'd-flex flex-row justify-content-between';
    divHeader.ChildOrder := 1;
    divHeader.ElementPosition := epRelative;
    divHeader.ElementFont := efCSS;
    divHeader.Role := '';
    WebHTMLDiv1.SetParentComponent(divHeader);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 3;
    WebHTMLDiv1.Top := 0;
    WebHTMLDiv1.Width := 100;
    WebHTMLDiv1.Height := 100;
    WebHTMLDiv1.ElementPosition := epRelative;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.HTML.BeginUpdate;
    try
      WebHTMLDiv1.HTML.Clear;
      WebHTMLDiv1.HTML.Add('<img src="images/rut-race-logo-100-transparent.png" />');
    finally
      WebHTMLDiv1.HTML.EndUpdate;
    end;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv2.SetParentComponent(divHeader);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 109;
    WebHTMLDiv2.Top := 0;
    WebHTMLDiv2.Width := 300;
    WebHTMLDiv2.Height := 100;
    WebHTMLDiv2.ElementClassName := 'd-flex text-white justify-content-center align-items-center';
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epRelative;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('On your marks... arriving early 2024');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
  finally
    divHeader.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
  end;
end;

end.